.FlyRocket{
    color: #FFF;
    font-size: max(min(7vw, 134px), 3rem);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 140px 0;
    /* padding: 0px 0; */
}

.FlyRocket .firstLine{
    padding-left: 2ch;
}

.FlyRocket .anim{
  width: 60vw;
}

.FlyRocket .secondLine span{
    display: inline-block;
    position: relative;
    width: 6ch;
    height: 1ch;
    overflow: visible;
}

.FlyRocket .secondLine span img{
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: moveUpDown 3s ease-in-out infinite;

}

@keyframes moveUpDown {
  0%, 100% {
    transform: translate(-60%, -50%);
  }
  50% {
    transform: translate(-30%, -70%);
  }
}


@media (max-width:750px) {
 
  .FlyRocket{
    padding: 100px 0;
    padding-bottom: 30px;
}

.FlyRocket .anim{
  width: 80vw;
}

}
.content{
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
}

.card{
    padding: 60px;
    border-radius: 60px;
border: 3px solid rgba(255, 255, 255, 0.08);
background: rgba(255, 255, 255, 0.06);
}


@media (max-width:600px) {
    .content{
        padding: 0 20px;
    }
    .card{
        padding: 30px;
        border-radius: 30px;
    }
}
.wallets {
  padding: 100px 0;
  position: relative;
}

.wallets .bg {
  border-radius: 380px;
  background: rgba(162, 91, 255, 0.85);
  filter: blur(269.4266662597656px);
  width: 380px;
  height: 380px;
  flex-shrink: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.wallets .content h1 {
  color: #fff;
  text-align: center;
  font-size: max(min(7vw, 123px), 2.2rem);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;
}

.wallets .content h1 span {
  color: #9747ff;
  font-weight: 500;
}

.wallets .content p {
  color: rgba(255, 255, 255, 0.49);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  padding: 1rem;
  line-height: 40px; /* 133.333% */
}

.wallets .content small {
  color: rgba(255, 255, 255, 0.49);
text-align: center;
margin: 0 auto;
width: 100%;
display: block;
font-size: 15px;
font-style: italic;
font-weight: 400;
line-height: 40px; /* 266.667% */
}

.wallets .cardContainer {
  margin: 100px 140px;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr)); */
  grid-gap: 1rem;
}

.wallets .cardContainer .WalletCard {
  padding: 30px 20px;
  min-height: 190px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.06);
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.8rem;
  row-gap: 1rem;
}

.wallets .cardContainer .WalletCard .imgCon {
  width: 78px;
  height: 78px;
  flex-shrink: 0;
  background-color: #5a15b4;
  border-radius: 50%;
  overflow: hidden;
}

.wallets .cardContainer .WalletCard .imgCon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wallets .cardContainer .WalletCard .detailCon {
  display: flex;
  gap: 0.3rem;
  flex-direction: column;
  justify-content: center;
}

.wallets .cardContainer .WalletCard h2 {
  color: #fff;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px; /* 114.815% */
}

.wallets .cardContainer .WalletCard p {
  color: rgba(255, 255, 255, 0.52);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
}

.wallets .cardContainer .WalletCard .icons {
  display: flex;
  gap: 0.8rem;
  align-items: flex-end;
  flex: 1 0 100%;
  justify-content: flex-end;
}

.wallets .cardContainer .WalletCard .icons img {
  height: 1.1rem;
  width: auto;
  display: block;
}

.wallets .cardContainer .WalletCard .icons a {
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

.wallets .cardContainer .WalletCard .icons a:hover {
  transform: scale(1.2);
}


@media (max-width:1440px) {
    .wallets .cardContainer {
        grid-template-columns: repeat(2, 1fr);
      }
}

@media (max-width: 750px) {
  .wallets {
    padding: 100px 0;
  }

  .wallets .content p {
    font-size: 18px;
    line-height: 26px; /* 133.333% */
  }

  .wallets .cardContainer {
    margin: 50px 24px;
    grid-template-columns: repeat(1, 1fr);
  }

  .wallets .cardContainer .WalletCard .imgCon {
    width: 50px;
    height: 50px;
    flex-basis: 50px;
  }
  .wallets .cardContainer .WalletCard .detailCon {
    flex-basis: calc(100% - 100px);
  }

  .wallets .cardContainer .WalletCard h2 {
    font-size: 24px;
    line-height: 28px; /* 114.815% */
  }

  .wallets .cardContainer .WalletCard p {
    font-size: 16px;
    line-height: 24px; /* 133.333% */
  }
}

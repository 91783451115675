.DashboardImage .Wrapper{
    display: flex;
    margin: 100px 0;
    padding-top: 12%;
    position: relative;
}

.DashboardImage .Wrapper .bg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 345px;
height: 345px;
flex-shrink: 0;
    border-radius: 345px;
background: rgba(162, 91, 255, 0.85);
filter: blur(183.6999969482422px);
}

.DashboardImage .imgCon{
    position: relative;
    flex-shrink: 0;
    width: 40%;
    height: fit-content;
    margin-right: 12vw;
    margin-top: 30px;
}

.DashboardImage .detailCon{
   flex-grow: 1;
   color: #FFF;
    text-align: right;
    font-size: 72.368px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.DashboardImage .detailCon .effect{
   display: flex;
   align-items: center;
   gap: 0.3ch;
   justify-content: flex-end;
}

.DashboardImage .detailCon span{
    color: #9747FF;
    font-weight: 700;
}

.DashboardImage .imgCon img{
    border-radius: 25px;
    box-shadow: 0px 4px 43px 0px rgba(0, 0, 0, 0.25);
    background-color: #000;
    padding: 12px;
}

.DashboardImage .imgCon .dashboard1{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    height: auto;
    left: 20%;
    top: -40%;
    transform-origin: left bottom;
    transform: scale(1.1);
    z-index: 10;
}

.DashboardImage .imgCon .dashboard2{
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    transition: all 0.5s ease;
    transform-origin: left bottom;
}

.DashboardImage .imgCon .dashboard2:hover{
   z-index: 10;
   transform: scale(1.1);
}

@media (max-width:750px) {
    .DashboardImage .Wrapper{
        flex-direction: column;
    }

    .DashboardImage .imgCon{
        width: 60%;
        margin-right: 0;
    }
    
    .DashboardImage .detailCon{
        font-size: 46px;
    }
    
    .DashboardImage .detailCon img{
        display: block;
        width: 2rem;
        transform: rotate(90deg);
        margin-bottom: 0.5rem;
    }
    
}
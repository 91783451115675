header.header{
    height: 100vh;
    position: relative;
    padding: 120px 0;
}

header.header .bg{
    position: absolute;
    left: 0;
    top: 0%;
   width: 100%;
   height: 100%;
   display: block;
   object-fit: cover;
   z-index: -1;
}

header.header .content {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    gap: 30px;
    position: relative;
}

header.header .content h1{
    color: #FFF;
    font-size: max(min(7vw, 134px), 2.5rem);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
}

header.header .content h1 span{
    font-size: max(min(5vw, 70px), 1.6rem);
    line-height: 1;
    color: #9747FF;
}

header.header .content p{
    color: rgba(255, 255, 255, 0.59);
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 134%; /* 36.18px */
    max-width: 42ch;
    margin-bottom: 10px;

}

header.header .content img.headerImage{
    display: block;
    width: 40%;
    max-width: 480px;    
}

header.header .content .headerAnim{
    display: block;
    width: 40%;
    max-width: 480px;  
}

header.header .content .headerAnim > *{
    display: block;
    width: 100%;
    height: 100%;    
}

header.header .content .logo{
    position: absolute;
    background: #2A2631;  
    right: 0px;
    bottom: -120px;
    padding: 1rem;
    margin: 2rem 80px;
    border-radius: 100px;

    display: flex;
    align-items: center;
    gap:0.5rem;
}

header.header .content .logo img{
   display: block;
   width: auto;
   height: 1.5rem;
}

@media (max-width:750px) {
    header.header {
        padding-bottom: 60px;
    }
    
    header.header .content {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
    header.header .content img.headerImage{
        display: block;
        width: 50%;
        max-width: 480px;  
        margin-left: auto;  
    }
    header.header .content .headerAnim{
        display: block;
        width: 40%;
        max-width: 480px;  
        margin-left: auto;  
    }

    header.header .content p{
        font-size: 16px;
    }
    
    header.header .content .detailcon{
        margin-right: auto;
    }

    header.header .content .logo{
        position: absolute;
        background: #2A2631;  
        right: 50%;
        bottom: -60px;
        padding: 0.8rem 1rem;
        margin: 1rem auto;
        transform: translateX(50%);
    }
    
    header.header .content .logo img{
        height: 1.1rem;
     }
     
}
.FAQ{
    padding: 100px 140px;
}

.FAQ h1{
    color: #9747FF;
    font-size: 161.661px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}

.FAQ .FAQCon{
    padding: 45px 0;
}

.FAQ .card{
    border-radius: 25px !important;
    padding: 30px !important;
    max-width: 889px;
    margin: 0 auto;
    margin-bottom: 15px;
}

.FAQ .card h3{
    color: #FFF;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: 40px; /* 133.333% */
}

.FAQ .card p{
    padding-top: 20px;
    color: rgba(255, 255, 255, 0.50);
font-size: 25px;
font-style: normal;
font-weight: 400;
line-height: 37px; /* 148% */
}

.FAQ .card .head{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
}

.FAQ .card .head button{
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    height: fit-content;
}

.FAQ .card .head .open .sub{
    display: none;
}
.FAQ .card .head .close .add{
    display: none;
}

.FAQ .card .body{
    height: fit-content;
    max-height: 0;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

@media (max-width: 750px) {
    .FAQ h1{
        font-size: 5rem;
    }
    .FAQ .FAQCon{
        padding: 20px 0;
    }

    .FAQ{
        padding: 100px 24px;
        padding-top: 0;
    }

    .FAQ .card .head{
        gap: 0.5rem;
    }

.FAQ .card{
    border-radius: 20px !important;
    padding: 20px !important;
}

.FAQ .card h3{
font-size: 24px;
line-height: 30px; /* 133.333% */
}

.FAQ .card p{
    font-size: 16px;
    line-height: 24px;
}
}